
import { IonRow, IonCol } from '@ionic/vue';
import {defineComponent} from 'vue';
import PersonaBaseLayout from '@/components/layout/base/BaseLayout.vue';
import PersonaForm from '@/components/form/Form.vue';
import AuthService from '@/services/auth.service';
import AlertService from '@/services/alert.service';

export default defineComponent({
    name: 'LoginPage',
    components: {
        PersonaBaseLayout,
        PersonaForm,
        IonRow,
        IonCol
    },
    setup()
    {
        // console.log('[LoginPage] isLoggedIn', AuthService.isLoggedIn);

        const login = () => {
            if (process.env.VUE_APP_MSAL_CLIENT_ID)
            {
                AuthService.login();
            }
            else {
                AlertService.presentAlert('Error', 'Missing env variables');
            }
        }

        return { login };
    }
});
